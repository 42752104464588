<template>
  <div class="main" data-title="任务列表" v-title>
    <van-cell-group title="电话量任务" v-if="taskTelData.length">
      <van-cell
        :key="index"
        :title="$store.state.userList[item.task_uid][0]"
        center
        v-for="(item, index) in taskTelData"
      >
        <template #label>
          <span class="content">
            <div class="items">
              <div class="item">
                <div class="label">电话量:</div>
                <div class="val">{{item.quantity}}/天</div>
              </div>
            </div>
            <div class="items">
              <div class="item">
                <div class="label">任务周期:</div>
                <div class="val">{{item.begin_date}} ~ {{item.end_date}}</div>
              </div>
            </div>
          </span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="检核任务" v-if="taskInsData.length">
      <van-cell
        :key="index"
        :title="$store.state.userList[item.task_uid][0]"
        center
        v-for="(item, index) in taskInsData"
      >
        <template #label>
          <span class="content">
            <div class="items">
              <div class="item">
                <div class="label">检核数量:</div>
                <div class="val">{{item.quantity}}条</div>
              </div>
            </div>
            <div class="items">
              <div class="item">
                <div class="label">检核周期:</div>
                <div class="val">{{item.begin_date}} ~ {{item.end_date}}</div>
              </div>
            </div>
          </span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="审核任务" v-if="taskConfData.length">
      <van-cell
        :key="index"
        center
        is-link
        v-for="(item, index) in taskConfData"
        :to="{name: 'customer-confirm', query: { trace_id: item.trace_id, sys_id: item.sys_id}}"
      >
        <template #title>
          {{$store.state.userList[item.trace.seller_uid][0]}} <span style="color: #f56c6c; font-size:12px;">战败</span>
        </template>
        <template #label>
          <span class="content">
            <div class="items">
              <div class="item">
                <div class="label">客户姓名:</div>
                <div class="val">{{trackList[item.client_id]}}</div>
              </div>
              <div class="item">
                <div class="label">联系方式:</div>
                <div class="val">{{item.trace.mobile}}</div>
              </div>
            </div>
            <div class="items">
              <div class="item">
                <div class="label">销售顾问:</div>
                <div class="val">{{$store.state.userList[item.trace.seller_uid][0]}}</div>
              </div>
              <div class="item">
                <div class="label">最近跟踪日期:</div>
                <div class="val">{{item.trace.trace_date}}</div>
              </div>
            </div>
          </span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-popup position="bottom" v-model="showPopup"></van-popup>
  </div>
</template>
<script>
// import { sellList, cfgList } from '@/unit/unit.js'
// import FieldSelect from '@/components/FieldSelect.vue'
// import FieldDate from '@/components/FieldDate.vue'
export default {
  components: {
    // FieldSelect
  },
  data() {
    return {
      showPopup: false,
      trackList: {},
      taskTelData: [],
      taskInsData: [],
      taskConfData: [],
      searchKey: {
        confirm_status: 0,
        page: 1,
        count: 1000
      }
    }
  },
  mounted() {
    this.getTaskData()
    this.getConfData()
    this.getTrackList()
  },
  methods: {
    getTaskData() {
      this.$axios.post('/task/lists').then(res => {
        if (res.data.code == 200) {
          this.taskData = res.data.data.list
          res.data.data.list.map(item => {
            if (item.task_type == 1) {
              this.taskTelData.push(item)
            }
            if (item.task_type == 2) {
              this.taskInsData.push(item)
            }
          })
        }
      })
    },
    getConfData() {
      this.$axios.post('/trace/confirm_lists', this.searchKey).then(res => {
        if (res.data.code == 200) {
          this.taskConfData = res.data.data.list
        }
      })
    },
    getTrackList() {
      this.$axios.post('/index/client').then(res => {
        this.trackList = res.data.data.list
      })
    }
  }
}
</script>
<style lang="less" scoped>
.items {
  display: flex;
  align-items: center;
  .item {
    flex: 1;
    display: flex;
    .label {
      padding: 3px 8px 3px 0;
      color: #606266;
    }
    .val {
      padding: 3px 0;
      font-weight: 500;
    }
  }
}
</style>